import "milligram/dist/milligram.min.css";
import Elevator from "elevator.js";

document.querySelector("#birthday")!.innerHTML = Math.floor(
  (new Date().getTime() - new Date(1986, 3, 29).getTime()) /
    1000 /
    60 /
    60 /
    24 /
    365
).toString();

var elevator = new Elevator({
  mainAudio:
    "https://github.com/tholman/elevator.js/raw/master/demo/music/elevator.mp3",
  endAudio:
    "https://github.com/tholman/elevator.js/raw/master/demo/music/ding.mp3",
});

(
  document.querySelector(".elevator-button") as HTMLButtonElement
).addEventListener("click", function () {
  if (window.scrollY > 3 * 1024) {
    elevator.elevate();
    return false;
  } else {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }
});

